import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";
import automatedTimesheet from "../../assets/image/jcare/automated-timesheet-calculation-on-camelo.png";

import bgSection from "../../assets/image/customers/jcare-support-service-feature.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(process.env.SIGNUP_URL);
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const JCareStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="How Camelo helps Jcare Support Services cut off wasted time and focus on customer care"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Camelo helps Jcare Support Services cut off wasted time
                    and focus on customer care
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-15 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-10 pt-lg-15 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link
                      to="https://jcaresupportservices.com.au/"
                      target="__blank"
                    >
                      {`Jcare Support Services `}
                    </Link>
                    is a dedicated disability and mental health support provider
                    based in Australia, offering comprehensive care services
                    such as
                    <Link to="https://www.ndis.gov.au" target="__blank">
                      {` NDIS `}
                    </Link>
                    support coordination and recovery coaching, mental health
                    support, support workers, cleaning services, and workshops.
                    They commit to providing personalised, high-quality support
                    to hundreds of people every day with services ranging from
                    in-home support to community and outpatient care.
                  </p>
                  <p className="gr-text-8 text-black">
                    Scheduling was once a major problem for Jcare Support
                    Services. Although they had tried different ways to solve
                    it, they couldn’t find anything that met their needs. After
                    searching on Google, Brooke Sanderson and Jo Thomas—the two
                    co-founders of Jcare Support Services—discovered Camelo and
                    immediately recognised it was the one for their team.
                  </p>
                  <h4 className="pt-8 pb-4">
                    A visionary leader in support services
                  </h4>
                  <p className="gr-text-8 text-black">
                    Brooke and Jo's journey in the mental health and disability
                    sector spans more than 20 years. With their wide experience
                    in the field and passion for making a difference, they took
                    a bold step during the COVID-19 pandemic to establish Jcare
                    Support Services.
                  </p>
                  <p className="gr-text-8 text-black">
                    ”I wanted to branch out to do things my way—so during COVID,
                    I took a chance and opened up my own business,” Brooke said.
                    As a radical leader, she valued the use of technology to
                    automate processes and create more space for meaningful
                    things.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "Having some automation which I can use to help and
                      support not only our clients but our team helps me manage
                      my time better."
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    The scheduling struggle: a common challenge
                  </h4>
                  <p className="gr-text-8 text-black">
                    Before discovering Camelo, scheduling was a significant pain
                    for Jcare Support Services. The process was slow,
                    time-consuming, and prone to errors.
                  </p>
                  <p className="gr-text-8 text-black">
                    Brooke and Jo frequently faced challenges such as forgetting
                    to update staff about shift changes, scheduling the wrong
                    staff for shifts, miscalculating work hours, and struggling
                    to communicate effectively with employees. These issues
                    consumed valuable time and risked compromising the quality
                    of service Jcare Support Services aimed to provide.
                  </p>
                  <h4 className="pt-8 pb-4">
                    Discovering Camelo: a turning point
                  </h4>
                  <p className="gr-text-8 text-black">
                    After an extensive Google search for scheduling solutions,
                    the two co-founders found Camelo. Their first experience was
                    remarkably smooth. "I did a trial last year to see if it was
                    suitable for what we were looking for, and it's perfect!"
                    Brooke recalled.
                  </p>
                  <p className="gr-text-8 text-black">
                    Even without formal training, Brooke found the platform
                    incredibly easy for users to follow. The intuitive interface
                    and comprehensive features convinced her immediately.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      ”It is easy, and I even hadn’t had any training. Very
                      user-friendly!”
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    Transforming team operations with Camelo
                  </h4>
                  <p className="gr-text-8 text-black">
                    One of the most significant improvements comes in staff hour
                    tracking. "Tracking staff hours was becoming incredibly
                    time-consuming as there were ALWAYS mistakes," Brooke
                    shared.
                  </p>
                  <p className="gr-text-8 text-black">
                    Now, with Camelo's
                    <Link
                      to="https://camelohq.com/features/time-and-attendance"
                      target="__blank"
                    >
                      {` automated timesheet calculation`}
                    </Link>
                    , the organisation’s owners transformed this process
                    entirely. There are hardly any mistakes in tracking
                    employees' working hours, and calculating payroll is so much
                    easier now.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      ’’It (Camelo) calculates and exports timesheets so this
                      can be automated. Now more time can be spent on other
                      things.”
                    </p>
                  </blockquote>
                  <img
                    className="w-100"
                    src={automatedTimesheet}
                    alt="Camelo’s time tracking feature"
                  />
                  <p className="text-center font-italic">
                    Export and calculate timesheet on Camelo
                  </p>
                  <h4 className="pt-8 pb-4">A tool that gives time back</h4>
                  <p className="gr-text-8 text-black">
                    "Camelo cuts off a lot of wasted time!" Brooke emphasised.
                    The app allows her and Jo to set schedules and then focus on
                    what truly matters—providing exceptional support to clients
                    and empowering their team.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      ”How easy it is to use. I set and forget!”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    “More time to focus on more important things like customer
                    services!” Brooke excitedly told us.
                  </p>
                  <h4 className="pt-8 pb-4">
                    A highly recommended app for businesses
                  </h4>
                  <p className="gr-text-8 text-black">
                    When asked if she would recommend Camelo to others, Brooke's
                    response was an enthusiastic yes. "It's a tool that
                    businesses can use to maximise their time spent on things
                    other than schedules," she explained.
                  </p>
                  <p className="gr-text-8 text-black">
                    Her overall satisfaction extends beyond just the app.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "I have been incredibly happy with everything—from
                      customer service to the app and website usage."
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    Looking ahead: discover possibilities and move forward with
                    Camelo!
                  </h4>
                  <p className="gr-text-8 text-black">
                    For Jcare Support Services, Camelo is more than just a
                    scheduling tool. It's a partner in their journey to provide
                    compassionate and efficient support service. Now they are
                    eager to discover more of Camelo’s features other than just
                    the timesheet calculation.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      ”Now we are going to get the full package so we can access
                      all of the amazing and easy-to-use features!”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    As the disability and mental health support sector continues
                    to grow, tools like Camelo are proving instrumental in
                    helping service providers like Jcare Support Services focus
                    on their core mission: caring for people and improving
                    lives.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default JCareStory;
